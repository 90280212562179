import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, EvidenceDto, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-evidence-item-form',
  templateUrl: './evidence-item-form.component.html',
  styleUrl: './evidence-item-form.component.scss',
})
export class EvidenceItemFormComponent extends BaseForm<EvidenceDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.Evidence);
  }

  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? {
        ...this.getDataKeyValueFormat(), linkedDocuments: this.formGroup.controls.documents.value ? [...this.formGroup.controls.documents.value] : [],
      }
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      links: new FormControl(null),
      documents: new FormControl(null),
      description: new FormControl(null),
    });
  }
}
